
@use "../util" as u;
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
.page-header-container {
    background-color: var(--dark-grey);
    padding: 5% 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    h1 {
        color: ivory;
        margin: 0;
        text-decoration: underline;
            text-decoration-thickness: 7px;
            // text-underline-position: above;
            text-decoration-color: aqua;
    }
    
}
#all-articles-page {
    padding: 2vw;
}

.all-articles-container {
    justify-content: space-evenly;
}


.journalism-tiles-container {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    // align-items: center;
}
.article-tile {
    
    // box-shadow: inset 0 0 35px 5px rgba(0, 0, 0, 0.25), inset 0 2px 1px 1px rgba(255, 255, 255, 0.9), inset 0 -2px 1px rgba(0, 0, 0, 0.25);
    // background: rgba($color: #262626, $alpha: 0.05);
    width: clamp(200px, 100%, 1200px);
    border: 1px solid var(--blackboard);
    margin: 2%;
    display: flex;
    // flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: 1s;
    
    color: var(--dark-text-color);
    img {
        max-width: 60%;
        max-height: 50%;
    }
    .header {
        font-weight: 700;
        text-decoration: underline;
        background-color: rgba($color: #ffffff, $alpha: 1.0);
    }
    .text-container {
        // display: flex;
        // justify-content: space-evenly;
        // background-color: ivory;
        width: 100%;
        display: flex;
        padding: 3%;
        p {
        
            font-weight: 500;
        
        }
        // flex-direction: column;
        justify-content: space-evenly;
        // $box-shadow: 0 20px 10px -20px rgba(0, 0, 0, 0.15) inset, 0 -20px 10px -20px rgba(0, 0, 0, 0.15) inset;
        // .publisher-text {
        //     box-shadow: $box-shadow;
        //     background-color: #ffe8d6}
        // .category-text { 
        //     box-shadow: $box-shadow;
        //     background-color: #ddbea9}
        // .date-text { 
        //     box-shadow: $box-shadow;
        //     background-color: #cb997e}
        p {
            padding: 2% 0;
            // font-size: clamp(15px, 2vw, 50px);
            margin: 0;
            width: 100%;
            text-align: center;
        }
    }
    p {
        align-self: center;
        justify-self: center;
    }
    &:hover {
        // box-shadow: 2px 4px 68px -15px rgba(56, 56, 56, 0.75);
        // -webkit-box-shadow: 2px 4px 68px -15px rgba(56, 56, 56, 0.75);
        // -moz-box-shadow: 2px 4px 68px -15px rgba(56, 56, 56, 0.75);
        // border-radius: 20px;
        // background-color: var(--dark-grey);
        // color: inherit;
        background-color: var(--blackboard);
        color: ivory;
        h3 {
            // color: var(--header-text-color);
        }
    }
}



#aak-page, #alwatan-page, #panorama-page, #sada-page, #alayam-page  {
    #paginated-container {
            background-color: var(--tan);
        }
}
/////////////
#article-page {
    
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            img {
                margin: 3%;
            }
            .title {
                margin-bottom: 50px;
            }
    
            .minimized {
                cursor: zoom-in;
                width: 35%
            }
    
            .maximized {
                cursor: zoom-out;
                width: 80%
            }
    
        
    .article-page-header {
        // padding: 5% 0;
        margin: 0;
        padding: 3% 0;
        width: 100%;
        background-color: var(--blackboard);
        color: ivory;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .page-title{
            margin: 0;
            color: var(--dark-grey);
            background-color: ivory;
            box-shadow: 0px -23px 25px 0px rgba(0, 0, 0, 0.17) inset, 0px -36px 30px 0px rgba(0, 0, 0, 0.15) inset, 0px -79px 40px 0px rgba(0, 0, 0, 0.1) inset, 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09);
            padding: 2%;
            border-radius: 20px 0 20px 0;
        }
        .article-nav-buttons {
            padding: 1%;
            margin:  0 1%;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: clamp(120px, 10%, 500px);

            .article-nav {
                // background-color: red;
                font-size: 2vw;
                color: whitesmoke;
                font-size: 25px;
            }

            .disabled {
                color: #bababa;
                pointer-events: none;
            }

        }
    }
    
}


#paginated-container {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    // background-color: var(--tan);
    flex-wrap: wrap;
    width: 100%;
    // min-height: 80vh;
    .page-link {
        // color: red;
        background-color: transparent;
        border: none;
        color: ivory
    }
    .active {
        font-weight: 600;
        // color: red;
    }
    ul {
        // position: absolute;
        // bottom: 0;
        margin: 0;
        background-color: var(--blackboard);
        display: flex;
        width: 100%;
        height: clamp(10px, 10%, 50px);
        justify-content: space-evenly;
        align-items: center;
        list-style: none;
        color: ivory;
        // background-color: #ffffff;
        padding: 1% 0;
    }
}




@include u.breakpoint-down(medium) {

#article-page {


        .minimized {
            
            width: 65%
        }

        .maximized {
            
            width: 90%
        }

    }
.article-page-header {
    flex-direction: column;
    min-height: 30vh;

}

    .article-tile {
        width: 100%;
        
        // height: 20vh;
        
    }
    

        #all-articles-page {
         
    
            .page-header-container {
                height: 25vh;
                height: clamp(205px, 25vh, 500px);
                flex-direction: column;
            }
        }

}

@include u.breakpoint-down(small) {
    
        .article-tile {
                width: 100%;
    
        
                .text-container {
 
                    flex-direction: column;
                    font-weight: 500;
                }
            }
    
}