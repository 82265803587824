@use "../util" as u;


html {
	width: 100vw;
	max-width: 100vw;
};

html, body, #root {
	padding: 0;
	margin: 0;
	min-height: 100vh;
}

body, #root {
	width: clamp(300px, 100vw, 1500px);
	margin: 0 auto;
	// width: 100%;
}



#root {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
main {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
	height: fit-content;
	min-height: 70vh;
	width: 100%;
	background-color: var(--grain);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}



video {
	width: 100%;
	align-self: center;
}

.list-no-bullets {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		margin-bottom: 2vw;
	}
}


.flex-list-x {
	display: flex;
	// justify-content: space-evenly;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}



.flex-list-y {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.padded-container {
	padding: 3%;
}

#search-container {
	background-color: ivory;	
	padding: 5% 0;
	// width: clamp(300px, 40%, 900px);
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	h2 {
		margin: 0;
		color: var(--blackboard);
		
	}
	select {
		
		
		background-color: var(--blackboard);
		color: ivory;
		
		border: none;
		padding: 1%;
		margin: 0;
		width: clamp(150px, 50%, 400px);
		height: 100%;
		font-family: inherit;
		font-size: inherit;
		cursor: pointer;
		line-height: inherit;
		outline: none;
		// background-color: red;
		text-align: center;
		transition: 0.3s ease-in;
		
		option {
			text-align: center;
			// font-size: 80px;
		}
	}
}



.page-title {
	// color: ivory;
	width: 100%;
	text-align: center;
	padding: 10%;
	margin: 0;
	text-align: center;
	text-decoration: underline;
	text-decoration-thickness: 3px;
	text-decoration-color: var(--tan);
	font-weight: 600;
	// background-color: var(--tan);
}

.banner {
	img {width: 100%;}
}

#error-page {
	width: 100vw;
	height: 100vh;
}

.tba-section {
	width: 100%;
	padding: clamp(100px, 10%,200px);
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--tan);
	color: ivory;
}