#interviews-page {
    min-height: 0;
    .play-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin: 10% 0;
      margin-top: 5%;
      h2 {
        text-align: center;
        width: 90%;
        font-weight: 500;
        padding: 5%;
      }
    }
  
    
}