@use "../util" as u;


.pdf-viewer {
    padding: 0;
    width: clamp(100px, 80%, 800px);
    height: clamp(300px, 100vh, 800px);
    margin: clamp(20px, 5%, 50px);
}


 @include u.breakpoint-down(small) {
     .pdf-viewer {
        width: 90%;
        height: 60vh;
        margin: 30px 0;
     }

}

// #publications-page {
//     display: flex;
//     flex-direction: column;
//     .navigation-container {        
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: space-evenly;
//         align-items: center;
//         // height: 45vh;
//         padding: 5% 0;
//         background-color: var(--blackboard);
//         color: ivory;
//     }
//     img {
//         max-width: 20%;
//     }
//     section {
//         padding: 5%;
//     }
// .publication-tile {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 2%;
//     margin: 2%;
//     p {
//         padding: 2%;
//     }
// }
//     #theatre {
//         .publication-tile {
//             border: 1px solid var(--blackboard);
            
//         }

//     }
//     #mawwal {
//         background-color: var(--tan);
//         .publication-tile{border: 1px solid var(--blackboard);}
//     }
//     #children-stories {
//         background-color: var(--blackboard);
//         color: ivory;
//         .publication-tile {
//                 border: 1px solid ivory;
//         }
//         h1 {
//             color: ivory;
//         }
//     }
// }



// @include u.breakpoint-down(medium) {
//     #publications-page {
//         .publication-tile {flex-direction: column;};
//     }

// }
////////////////////////////////////////////////////////////////////////////////////////////

// .publications-container {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-evenly;
//     // align-items: ri;
//         @include u.breakpoint-down(small) {
//                 align-items: center;
//                 // width: 80%;
        
//             }
// }
// .publication-tile {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     margin: 10px 0;
//     padding: 1vw 4vw;
//     width: 100%;
//     height: 45vh;
//     border-radius: clamp(10px, 10%, 20px);
//     img {
//         // width: clamp(100px, 25%, 400px);
//         height: 90%;
//     }
//     p {
//         padding: 1vw;
//     }
//     transition: 0.8s;
//     &:hover {
//         background-color: var(--rich-black);
//         p {
//             color: var(--ivory);
//         }
//     }
//     @include u.breakpoint-down(small) {
//         flex-direction: column;
//         height: 60vh;
//         padding: 4vw 1vw;
//         img {
//             width: 70vw;
//             height: auto;
//         }

//     }
// }


