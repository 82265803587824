@use '../util/' as u;

.page-header {
    width: 100%;
    img {
        width: 100%;
    }
    
}




///////////////////



// @include u.breakpoint-down(medium) {
//     .page-header {
//             height: 55vh;
    
//             .header-background {
//                 z-index: 1
//             }
    
            
    
//             .header-content {
//                 background-color: var(--nav-background-color);
            
//                 flex-direction: column-reverse;}
//             }

// }

@include u.breakpoint-down(medium) {
.page-header {
    // height: 100vh;
    // .header-background {
    //     display: none;

    // };
    .header-background { height: 40vh; width: auto;}
    .header-content{
        // background-color:var(--nav-background-color);
        flex-direction: column-reverse;
        justify-content: center;
        .header-headshot {
            // height: 50%;
            // width: auto;
            margin-bottom: clamp(20px, 7%, 100px);
        }
        h3 { 
            width: fit-content;
            align-self: center;
            // span { background-color: rgba($color: #ffffff, $alpha: 0)}
            // text-align: center;
            span { background-color: transparent}
        }
    };
}
}