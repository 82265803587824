
#photo-page {
    // min-height: 100vh;

    .photo-page-header {
            // padding: 5% 0;
            margin: 0;
            padding: 3% 0;
            width: 100%;
    
            .page-title {
                margin: 0;
                color: var(--dark-grey);
                background-color: ivory;
                box-shadow: 0px -23px 25px 0px rgba(0, 0, 0, 0.17) inset, 0px -36px 30px 0px rgba(0, 0, 0, 0.15) inset, 0px -79px 40px 0px rgba(0, 0, 0, 0.1) inset, 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09);
                padding: 2%;
                border-radius: 20px 0 20px 0;
            }
    
            background-color: var(--blackboard);
            color: ivory;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
    
            .photo-nav-buttons {
                padding: 1%;
                margin: 0 1%;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: clamp(120px, 10%, 500px);
    
                .photo-nav {
                    // background-color: red;
                    font-size: 2vw;
                    color: whitesmoke;
                    font-size: 25px;
                }
    
                .disabled {
                    color: #bababa;
                    pointer-events: none;
                }
    
            }
        }
        .photo-sec {
            width: 100%;
            min-height: 50vh;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                    width: clamp(200px, 80%, 600px);
                    height: auto;
                    margin: 5%;
                }
        }
    
}

.photo-section {
    // height: 50vh;
    width: 100%;
    #paginated-container {
        background-color: var(--tan);
        ul {
            background-color: var(--blackboard);
            .page-link, a {color: ivory};
        }
    }
}

.photo-tile {
    
    width: clamp(400px, 40vw, 600px);
    height: clamp(400px, 60vw, 1000px);
    // margin: 4%;
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid var(--blackboard);
    margin: 2%;
    transition: 1s;
    color: var(--dark-text-color);

    img {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
        border: 10px solid ivory;
        // width: 90%;
        max-width: 90%;
        max-height: 90%;
    }
    
        
}