@use '../util/' as u;



#landing-page {

    .intro {
        color: var(--blackboard);
        padding: 10%;
        width: 100%;
        h3 {line-height: 2.5};
        columns: 700px;
        column-gap: 100px;
        // a, a:visited {
            
        // }

        ul {
            list-style-type: square;
        }
    }

    
}






// #landing-page {
//     // background-color: var(--tan);
//     // color: var(--ivory);
//     display: flex;
//     flex-direction: column;
//     justify-content: flex;
//     background-color: rgba($color: rgb(227, 227, 227), $alpha: 0.9);
//     // align-items: center;
//     // justify-content: center;
//     padding: 0;
//     margin: 0;
//     // width: clamp(200px, 100%, 1200px);
//     width: 100%;
//     min-height: 75vh;
//     position: relative;
//     #headshot {
//         position: absolute;
//         // width: 35%;
//         height: clamp(200px, 20vw + u.rem(300), 650px);
//         // align-self: end;
//         // justify-self: center;
//         left: 1%;
//         top: 50%;
//         transform: translate(0%, -50%);
//         // left: calc(width - 1000);
//         // opacity: 0.9;
//         // border-radius: 20px 0 0 20px;
//         // border-radius: 20px;
//         box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
//     }
    
//     #header-top {
//         width: 100%;
        
//     }
    
//     .text-section {
//         padding: 3%; 
//         width: 60%;
//         p {
//             font-size: clamp(u.rem(20), 0.5vw + u.rem(15), u.rem(40));
//         }
//         .signature {
//             // padding: 1% 0;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             width: 40%;
//             p {
//                 font-family: 'Aref Ruqaa', serif;
//                 font-size: clamp(u.rem(20), 1vw + u.rem(10), u.rem(40));
//                 margin: 0;
//             }
//             span { font-size: clamp(u.rem(20), 1vw + u.rem(10), u.rem(40));}
//         }
//     }
// }




// // #landing-page {
    
// //     $font-size: clamp(u.rem(20), u.rem(10) + 1vw, u.rem(80));
// //     display: flex;
// //     flex-direction: row;
// //     justify-content: space-between;
// //     align-items: center; 
// //     max-height: 70vh;   
// //     .text-section {
// //         background-color: var(--ivory);
// //         border-radius: 80px 80px 80px 0;
// //         width: 50%;
// //         padding: 2vw;
// //         display: flex;
// //         flex-direction: column;
// //         align-items: center;
// //         justify-content: center;
// //         p {
// //             width: 80%;
// //             color: var(--dark-text-color);
// //             font-size: $font-size;
// //             // font-weight: bold;
// //             // line-height: 1.5;
// //         }
// //         .signature {
// //             margin-top: 5%;
// //             margin-bottom: 0;
// //             font-family: 'Aref Ruqaa', serif;
// //             // align-self: flex-end;
// //             width: 80%;
// //         }
        
// //     }
    
// //     .landing-photo-section {
// //         $size: clamp(300px, 35vw, 750px);
// //         width: $size;
// //         height: $size;
// //         background-image: url('../images/baba-logo.png');
// //         background-position: center;
// //         background-size: cover;
        
// //     }
// // }

// // @include u.breakpoint-down(medium) {
// //     #landing-page {
// //         .main-section {
// //             height: 70%;
// //             flex-direction: column-reverse;
// //             // flex-direction: column-reverse;
// //             #headshot {width: 30%};
// //             .text-section {
// //                 width: 80%;
// //             }
// //         }
// //     }
// // }


// // @include u.breakpoint-down(small) {
// //     #landing-page {
// //         .main-section {
// //             height: 90%;

// //             // flex-direction: column-reverse;
// //             flex-direction: column-reverse;
// //             #headshot {
// //                 width: 50%
// //             }

// //             ;

// //             .text-section {
// //                 width: 90%;
// //                 padding: 15% 5%;
// //             }
// //         }
// //     }
// // }




// @include u.breakpoint-down(medium) {
//     // #landing-page {
//     //     #headshot {
//     //         top: 5%;
//     //         left: 50%;
//     //         transform: translate(-50%,0);
//     //     }
//     // }
// }