@use '../util/' as u;

footer {
    border-top: 10px solid var(--tan);
    width: 100%;
    background-color: var(--blackboard);
    color: ivory;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    section {
        padding: 3%;
        
    }

    .sm-section, .contact-us-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .icon-section {
        display: flex;
        width: clamp(200px, 100%, 800px);
        justify-content: space-between;
        
    }
    .sm-icons, .contact-us-footer {
        a, a:visited {
            color: var(--blackboard);
            margin: 10px;
            img {
                width: clamp(40px, 3vw, 80px);
            }
        }
        
    }

    .sm-icons {
        display: flex;
        align-items: center;
    }
    
        
    
    .copyright-section {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    // background-color: var(--grain);
}

@include u.breakpoint-down(small) {
    footer {
        padding-top: 10%;
        .icon-section {
            flex-direction: column-reverse;
            div {
                    margin: 5% 0;
            }
        }
    }
}