
#mawwal-list-page {
    #paginated-container {
        background-color: var(--blackboard);
        ul {
            background-color: ivory;
            .page-link,
                a {
                    color: var(--blackboard);
                }
            
                
        }
    }
}


.mawwal-tile {
    
    // margin: 4%;
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--blackboard);
    margin: 2%;
    transition: 1s;
    color: var(--dark-text-color);
    border: 10px solid ivory;
    img {
        // width: 90%;
        // max-width: 90%;
        // max-height: 90%;
        width: clamp(180px, 40vw, 600px);
        // height: clamp(400px, 60vw, 1000px);
    }
    
}


.mawwal-page-header {
    // padding: 5% 0;
    margin: 0;
    padding: 3% 0;
    width: 100%;

    .page-title {
        margin: 0;
        color: var(--dark-grey);
        background-color: ivory;
        box-shadow: 0px -23px 25px 0px rgba(0, 0, 0, 0.17) inset, 0px -36px 30px 0px rgba(0, 0, 0, 0.15) inset, 0px -79px 40px 0px rgba(0, 0, 0, 0.1) inset, 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09);
        padding: 2%;
        border-radius: 20px 0 20px 0;
    }

    background-color: var(--blackboard);
    color: ivory;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .mawwal-nav-buttons {
        padding: 1%;
        margin: 0 1%;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: clamp(120px, 10%, 500px);

        .mawwal-nav {
            // background-color: red;
            font-size: 2vw;
            color: whitesmoke;
            font-size: 25px;
        }

        .disabled {
            color: #bababa;
            pointer-events: none;
        }

    }
}

.show-img {
    width: clamp(200px, 80%, 600px);
    height: auto;
    margin: 5%;
}