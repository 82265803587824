@use "functions" as f;

// 700px, 900px, 1440px
$breakpoints-up: (
	"medium": f.em(700),
	"large": f.em(900),
	"x-large": f.em(1440),
);
// 699.98px, 899.98px, 1439.98px
$breakpoints-down: (
	"small": f.em(699.98),
	"medium": f.em(899.98),
	"extra-medium": f.em(1200),
	"large": f.em(1439.98),
);

@mixin breakpoint($size) {
	@media (min-width: map-get($breakpoints-up, $size)) {
		@content;
	}
}

@mixin breakpoint-down($size) {
	@media (max-width: map-get($breakpoints-down, $size)) {
		@content;
	}
}
