@use '../util/' as u;


$videoWidth: 70%;

#theatre-page {
    .publishers-container {
        color: var(--blackboard);
    }
}

.video-container {
    position: relative;
    overflow: hidden;
    margin-bottom: 10%;
    width: clamp(200px, 80%, 900px);
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
        
    /* Then style the iframe to fit in the container div with full height and width */
    .youtube-player {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    
}
#play-page {
    .pdf-section {
        width: 100%;
        background-color: var(--blackboard);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .play-cover {
        width: clamp(200px, 70%, 600px);
    }
    video {
        width: 70%;
        margin-bottom: 5%;
    }
    .image-section {
        width:100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        // background-color: var(--blackboard);
        img {
            margin: 2%;
            width: clamp(300px, 45%, 600px);
        }

    }
    
    .song-section{
        background: var(--tan);
        width: 100%;
        .page-title {
            color: var(--blackboard);
            text-decoration-color: ivory;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        flex-wrap: wrap;
        audio {
            margin: 2%;
            width: clamp(200px, 80%, 600px);
        }
        padding-bottom: 15%;
    }

    .pdf-section {
        padding: 15%;
        .page-title {
            color: ivory
        }
    }

    .pdf-section.mobile {
            padding: 15%;
            width: 100%;
            a {
                padding: 5%;
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                background-color: var(--tan);
                color: var(--blackboard);
                border-radius: 20px;                
                img {
                    width: 7%;
                }
                h2 {
                    margin: 0;
                }
            }
    }
}

.theatre-tiles-container {
    
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: var(--tan);
    width: 100%;
    margin: auto;
    padding: 10%;
    .box-link, .box-link:visited, .box-link:active {
        $size: clamp(200px, 30vw, 500px);
        height: $size;
        width: $size;
        color: var(--blackboard);
        border-color: var(--blackboard);
        &:hover {
                border-radius: 100;
                background-color: var(--blackboard);
                color: ivory;
            }
    }
   
    
}

@include u.breakpoint-down(small) {
    .theatre-tiles-container {
        .box-link, .box-link:visited, .box-link:active {
            width: 100%;
            &:hover {
                border-radius: 0;
                
            }
        }

    }
}