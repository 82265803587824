@use "../util" as u;
#journalism-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.publishers-container {
    width: 100%;
    padding: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // background-color: var(--tan);
    background-color: var(--blackboard);

}
.box-link, .box-link:visited, .box-link:active {
    $size: clamp(300px,30vw, 1000px);
    margin: 3%;
    width: $size;
    height: $size;
    font-size: clamp(u.rem(15), u.rem(10) + 4vw, u.rem(24));
    

    // background-color: red;
    border: 3px solid var(--tan);
    color: var(--tan);
    // color: ivory;
    // color: var(--blackboard);
    // border: 3px solid var(--blackboard);
    transition: 0.6s;
    &:hover {
        border-radius: 100%;
        // background-color: var(--blackboard);
        // color:  var(--ivory);
        background-color: var(--tan);
        color:  ivory;
    }
}

@include u.breakpoint-down(small) {
    
    .publishers-container {
        // flex-direction: column;
        .box-link { 
            width: 90%;
            &:hover {
                border-radius: 0;
            }
        }
    }

    
}