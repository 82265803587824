@use "../util" as u;

#sada-page {

    .page-title {
        // color: ivory;
        width: 100%;
        text-align: center;
        padding: 10%;
        margin: 0;
        text-align: center;
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-decoration-color: var(--tan);
        font-weight: 600;
        // background-color: var(--tan);
    }

    .sada-links-container {
        // background-color: red;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        // height: 45vh;
        padding: 5% 0;
        width: 100%;
        background-color: var(--blackboard);
        color: ivory;


        // width: 100%;
        // display: flex;
        // // flex-direction: column;
        // justify-content: space-evenly;
        // flex-wrap: wrap;

        // // margin: 2 0;
        // // padding: 1vw 0;
        // font-size: clamp(20px, 4vw, 60px);
        // // border-bottom: 3px solid var(--dark-text-color);
        // a {
        //     width: clamp(100px, 20%, 300px);
        //     border: 1px solid var(--dark-text-color);
        // }

    }


    .sada-articles-container {
        width: 100%;

    }
}

.header-link:visited {
    text-decoration: none;
    // color: var(--header-link-color);
}


.header-link,
.header-link-active {
    width: 20%;
    text-align: center;
    font-size: clamp(20px, 2vw, 60px);
    height: fit-content;
    padding: 1%;
    // border-radius: 1vw 1vw 0 0;
}


.header-link {
    color: ivory;
    transition: 0.8s;

    &:hover {

        color: aquamarine;
    }
}

.header-link-active {

    padding: 1%;
    // color: #262626;
    color: var(--tan);
    font-weight: 700;

    // text-decoration: underline;
    // text-decoration-color: red;
    // text-decoration-thickness: 3px;
    &:hover {
        color: aqua;
    }
}



@include u.breakpoint-down(small) {
    #sada-page {


        .sada-links-container {

            a {
                width: 100%;
                // border: 1px solid #262626
            }
        }

    }


}