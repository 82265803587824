@use "../util/" as u;

html {
	box-sizing: border-box;
	font-size: 100%;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html,
body {
	max-width: 100vw;
}

body {
	margin: 0;
	padding: 0;
	background-color: var(--background-color);
	color: var(--dark-text-color);
}

.disabled {
	color: #bababa;
	pointer-events: none;
}

.minimized {
	width: 35%
}

.maximized {
	width: 80%
}
