@use "../util" as u;


@import url('https://fonts.googleapis.com/css2?family=Amiri:wght@400;700&family=Aref+Ruqaa:wght@400;700&family=Tajawal:wght@200;300;400;500;700;800&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Amiri:wght@400;700&family=Aref+Ruqaa:wght@400;700&display=swap');


@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
* {
	// font-family: "Raleway", sans-serif;
	// font-family: "Noto Serif", serif;
	// font-family: 'Amiri', serif;
	font-family: 'Tajawal', sans-serif;
	text-align: right;
}

h1,
h2,
h3,
h4 {
	font-weight: 500;
	line-height: 1.1;
	width: fit-content;
	margin-top: 0;
	margin-bottom: 10px;
	// border-bottom: 5px solid var(--header-text-underline-color);
	// text-decoration: underline;
	// text-decoration-thickness: 3px;
	// text-decoration-color: var(--header-text-underline-color);
	color: var(--header-text-color);
}

h1 {
	font-size: clamp(u.rem(24), u.rem(16) + 2.5vw, u.rem(45));
	// color: var(--header-text-color);
	margin-bottom: clamp(10px, 2vw, 80px);
}

h2 {
	font-size: clamp(u.rem(20), u.rem(12) + 1.2vw, u.rem(36));
}

h3 {
	font-size: clamp(u.rem(19), u.rem(12) + 1vw, u.rem(28));
}

h4 {
	font-size: clamp(u.rem(17), u.rem(10) + 1vw, u.rem(24));
}

p {
	margin-top: 0;
	font-size: clamp(u.rem(15), u.rem(9) + 1vw, u.rem(20));

	// padding: u.rem(10);
	text-align: justify;
}

@include u.breakpoint-down(small) {
	 p {
		margin-top: 0;
		font-size: clamp(u.rem(15), u.rem(9) + 3vw, u.rem(20));
		text-align: justify;
	 }
	.link,
	.link:visited,
	.link:active {
		li{
		font-size: clamp(u.rem(15), u.rem(9) + 2.5vw, u.rem(20));}
	}

}



li {
	// font-size: clamp(u.rem(15), u.rem(5) + 1vw, u.rem(20));
	// font-size: clamp(u.rem(10), u.rem(9) + 1vw, u.rem(18));
}

a,
a:visited,
a:active {
	text-decoration: none;
	color: inherit;
}

.link,
.link:visited,
.link:active {
	color: inherit;
	padding-bottom: 0.3vw;
	transition: 0.5s;
	&:hover {color: #62b4ae};
	margin: 2vw 0;
	// font-weight: 600;
	font-size: clamp(u.rem(10), u.rem(12) + 0.7vw, u.rem(20));
	// font-style: italic;
	// border-bottom: 1px solid;
	overflow-wrap: break-word;
}

.linebreak {
	white-space: pre-line;
	line-height: 1.5;
	@include u.breakpoint-down(medium) {
		line-height: 1.3;
	}
	// line-height: 200%;
	// line-gap-override: 200%;
}


.clickable-box, .clickable-box:visited, .clickable-box:active {
	color: inherit;
	
}

.capsule-header {
	background-color: var(--b-yellow);
	color: var(--b-charcoal);
	width: fit-content;
	padding: clamp(15px,5vw, 40px);
	border-radius: clamp(u.rem(24), u.rem(16) + 2vw, u.rem(40)) 0 clamp(u.rem(24), u.rem(16) + 2vw, u.rem(40)) 0;
}


.underlined-header {
	color: ivory;
		text-decoration: underline;
		text-decoration-thickness: 7px;
		text-decoration-color: aqua;
}

